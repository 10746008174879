<template>
  <!-- confirm START -->
  <transition name="fade" mode="out-in">
    <div class="dialog dialog-confirm" v-if="loadingVisible">
      <div class="dialog-confirm-container">

        <div class="inner">
          <div class="icon"></div>
          <h4 v-html="headline"></h4>
          <div class="excerpt" v-html="excerpt"></div>

          <div class="dialog-footer">
            <div
              class="btn-inverted close"
              v-on:click="resetStoreCart()"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
              v-html="'Nein'"
            ></div>
            <div
              class="btn-default confirm btn-default-blue"
              v-on:click="confirm(data)"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
              v-html="'Ja'"
            ></div>
          </div>

          <!-- cart:actions:checkout:abort START -->
          <div
            class="abort"
            v-on:click="resetStoreCart()"
            v-if="false"
          >
            <span v-html="'Einkauf abbrechen'"></span>
          </div>
          <!-- cart:actions:checkout:abort END -->
        </div>
      </div>
    </div>
  </transition>
  <!-- confirm END -->
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'dialog-confirm',
  props: {
    data: {
      type: String,
    },

    loadingVisible: {
      type: Boolean,
      default: false,
    },

    headline: {
      type: String,
    },

    excerpt: {
      type: String,
    },

    open: {
      type: Function,
    },

    close: {
      type: Function,
    },

    confirm: {
      type: Function,
    },
  },
  methods: {
    ...mapActions([
      'clearCart',
    ]),

    touchStart(event) {
      event.target.classList.add('pressed');
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
    },

    resetStoreCart() {
      // reset store cart
      this.clearCart();
      // redirect to home
      // this.$router.push('/');
      // redirect to home + reload app
      window.location.assign('/');
    },
  },
};
</script>
